import { createContext, useState } from 'react';

export const DirectRequestFormContext = createContext();

const initRentalObject = {
  name: '',
  productReferenceId: null,
  salesforceId: null,
  productGroupName: null,
  productGroupId: null,
  automatedOffer: false,
  minLeadTime: null,
  rentalPriceBasis: null,
  rentalPrice: null,
  invoiceBasis: null,
};

const initialData = {
  rentalObject: { ...initRentalObject },
  count: 1,
  dateFrom: null,
  dateTo: null,
  rentalHours: null,
};

export const DirectRequestFormProvider = ({ children }) => {
  const [requestData, setRequestData] = useState(initialData);

  const resetRentalObjectData = () => {
    setRequestData(prev => ({
      ...prev,
      rentalObject: { ...initRentalObject },
      count: 1,
      rentalHours: null,
    }));
  };

  const updateRequestData = fields => {
    setRequestData(prev => ({ ...prev, ...fields }));
  };

  const resetRequestData = () => {
    setRequestData(initialData);
  };

  return (
    <DirectRequestFormContext.Provider
      value={{
        requestData,
        updateRequestData,
        resetRentalObjectData,
        resetRequestData,
      }}>
      {children}
    </DirectRequestFormContext.Provider>
  );
};
