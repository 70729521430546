import { v4 as uuidv4 } from 'uuid';

import dametisApi from '@/helpers/dametisApi';

const parseFetchedAccessories = (accessories = []) =>
  accessories.map(
    ({
      product_family_name: name,
      included,
      invisible,
      standard,
      salesforce_id: salesforceId,
      automated_offer: automatedOffer,
      min_lead_time: minLeadTime,
      rental_price_basis: rentalPriceBasis,
      rental_price: rentalPrice,
    }) => ({
      itemData: {
        name,
        included,
        invisible,
        standard,
        salesforceId,
        automatedOffer: Boolean(automatedOffer),
        minLeadTime,
        rentalPriceBasis,
        rentalPrice,
      },
      count: included ? 1 : 0,
      id: uuidv4(),
    })
  );

export const fetchAccessories = async (productId, params = {}) => {
  try {
    const {
      data: { data: accessories },
    } = await dametisApi.queryAccessories({
      productReferenceId: productId,
      // max size so all the accessories get fetched at once
      size: 200,
      ...params,
    });

    return parseFetchedAccessories(accessories);
  } catch {
    // fail silently
  }
};

export const parseFetchedQuestions = (questions = []) =>
  questions.map(
    ({
      required,
      question: { name, answer_type, choices, has_unit, unit, tooltip },
    }) => ({
      question: name,
      answer: answer_type === 'number_range' ? { from: '', to: '' } : '',
      required,
      answerType: answer_type,
      choices: choices?.map(({ name }) => name),
      hasUnit: has_unit,
      unit: unit?.name,
      tooltip: tooltip,
    })
  );

export const fetchQuestions = async productGroupId => {
  try {
    const {
      data: { data: questions },
    } = await dametisApi.getProductGroupQuestions({
      productGroupId,
    });

    return parseFetchedQuestions(questions);
  } catch {
    // fail silently
  }
};
